<ion-row class="ion-align-items-center ion-justify-content-center">
  <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">
    <h3 class="ion-margin-start">
      <ion-text color="amber">{{ profile_integration.integration.name }} Integration</ion-text>
    </h3>
    <p>{{ profile_integration.integration.description }}</p>

    <form [formGroup]="createIntegrationForm" (ngSubmit)="submitForm()">
      <ion-card class="main-card">
        <ion-card-content>  
  
          <ion-row>
            <ion-col class="ion-no-padding ion-text-center">
              <ion-button type="submit" expand="block" color="darkpurple">Integrate</ion-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </form>  
  </ion-col>
</ion-row>