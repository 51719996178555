import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/services';
import { AccountIntegrationInterface } from '@kiddy-cash/common';

@Component({
  standalone: false,
  selector: 'integration-column-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss'],
})
export class ColumnDepositComponent  implements OnInit {
  @Input() account_integration: AccountIntegrationInterface | undefined;
  @Input() bank_account: any;

  staticBaseUrl: string =  environment.staticBaseUrl;

  @Output() depositIntegrationSubmitForm = new EventEmitter<any>();

  constructor(
    private toastService: ToastService,
  ) { }

  ngOnInit() {}

  emitOutput(data: any) {
    this.depositIntegrationSubmitForm.emit(data);
  }
}
