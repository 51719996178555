
<ion-row class="ion-align-items-center ion-justify-content-center">
  <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">
    <ion-accordion-group [multiple]="true" value="bank_transfer">
      <ion-accordion value="bank_transfer">
        <ion-item slot="header" color="light">
          <ion-label>Your Bank Details</ion-label>
        </ion-item>
        <div class="ion-padding" slot="content">
          <br />

          <ion-list [inset]="true">
            <ion-item>
              <ion-label>Account Holder</ion-label>
              <ion-text slot="end">{{ bank_account["bank-addresses"][0]["account-holder"] }}</ion-text>
            </ion-item>

            <ion-item>
              <ion-label>Bank Id Code</ion-label>
              <ion-text slot="end">{{ bank_account["bank-addresses"][0]["bank-id-code"] }}</ion-text>
            </ion-item>

            <ion-item>
              <ion-label>Bank Id</ion-label>
              <ion-text slot="end">{{ bank_account["bank-addresses"][0]["bank-id"] }}</ion-text>
            </ion-item>

            <ion-item>
              <ion-label>Account Number</ion-label>
              <ion-text slot="end">{{ bank_account["bank-addresses"][0]["account-number"] }}</ion-text>
            </ion-item>

            <ion-item>
              <ion-label>Account Number Code</ion-label>
              <ion-text slot="end">{{ bank_account["bank-addresses"][0]["account-number-code"] }}</ion-text>
            </ion-item>
          </ion-list>

          <br />
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-col>
</ion-row>