<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">
        <ion-accordion-group value="stk_push">

          <ion-accordion value="stk_push">
            <ion-item slot="header" color="light">
              <ion-label>Pay via Mpesa</ion-label>
            </ion-item>
            <div class="ion-padding" slot="content">
              <form [formGroup]="payIntegrationForm" (ngSubmit)="submitForm()">
                <ion-list [inset]="true">
                  <ion-item>
                    <ion-label>Amount</ion-label>
                    <ion-text slot="end">{{ transaction.from_account.country.currency.symbol }} {{ transaction.amount | number:'1.0-2' }}</ion-text>
                  </ion-item>
    
                  <ion-item>
                    <ion-label>Fee</ion-label>
                    <ion-text slot="end">{{ transaction.from_account.country.currency.symbol }} {{ transaction.fee | number:'1.0-2' }}</ion-text>
                  </ion-item>
      
                  <ion-item>
                    <ion-label>Purpose</ion-label>
                    <ion-text slot="end">{{ transaction.purpose }}</ion-text>
                  </ion-item>  

                  <br />

                  <ion-item fill="outline" class="transparent-background" lines="none">
                    <ion-input
                      [clearInput]="true"
                      label="Phone number"
                      labelPlacement="floating"
                      placeholder="Phone number"
                      formControlName="phonenumber"
                      type="tel"
                      [maskito]="maskitoPhoneOptions"
                      [maskitoElement]="maskPredicate"            
                      inputmode="numeric"
                  ></ion-input>
                  </ion-item>
        
                  <br />

                  <ion-item fill="outline" class="transparent-background" lines="none">
                    <ion-input
                      type="number"
                      label="Amount"
                      labelPlacement="floating"
                      placeholder="Total"
                      formControlName="amount"
                      inputmode="numeric"
                      [value]="transaction.amount + transaction.fee"
                      readonly
                    ></ion-input>
                  </ion-item>

                </ion-list>

                <ion-button
                  expand="full"
                  color="darkpurple"
                  type="submit"
                  [disabled]="!payIntegrationForm.valid">
                  Pay
                </ion-button>
              </form>
            </div>
          </ion-accordion>

          <ion-accordion value="stripe">
            <ion-item slot="header" color="light">
              <ion-label>Pay via Stripe</ion-label>
            </ion-item>
            <div class="ion-padding" slot="content">
              <br />

              <ion-list [inset]="true">
                <ion-item>
                  <ion-label>Amount</ion-label>
                  <ion-text slot="end">{{ transaction.from_account.country.currency.symbol }} {{ transaction.amount | number:'1.0-2' }}</ion-text>
                </ion-item>
  
                <ion-item>
                  <ion-label>Fee</ion-label>
                  <ion-text slot="end">{{ transaction.from_account.country.currency.symbol }} {{ transaction.fee | number:'1.0-2' }}</ion-text>
                </ion-item>
  
                <ion-item>
                  <ion-label>Total</ion-label>
                  <ion-text slot="end">{{ transaction.from_account.country.currency.symbol }} {{ ( transaction.amount + transaction.fee ) | number:'1.0-2' }}</ion-text>
                </ion-item>
      
                <ion-item>
                  <ion-label>Purpose</ion-label>
                  <ion-text slot="end">{{ transaction.purpose }}</ion-text>
                </ion-item>  
              </ion-list>
  
              <br />
  
              <ion-button
                expand="full"
                color="darkpurple"
                type="button"
                (click)="payWithStripe()"
              >
                Click to Pay with Stripe 
              </ion-button>
            </div>
          </ion-accordion>

        </ion-accordion-group>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>