import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import {maskitoPhoneOptionsGenerator} from '@maskito/phone';
import metadata from 'libphonenumber-js/mobile/metadata';
import { MaskitoOptions, MaskitoElementPredicate } from "@maskito/core";
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { ToastService } from 'src/app/services';
import { StripePaymentInterface, TransactionInterface } from '@kiddy-cash/common';
import { ModalController } from '@ionic/angular';
import { PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe';

@Component({
  standalone: false,
  selector: 'integration-dtb-astra-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
})
export class DtbAstraPayComponent  implements OnInit {
  @Input() transaction!: TransactionInterface;
  @Input() stripe_data!: StripePaymentInterface;

  staticBaseUrl: string =  environment.staticBaseUrl;

  payIntegrationForm: FormGroup = new FormGroup({
    phonenumber: new FormControl(),
    amount: new FormControl(),
  });

  readonly maskPredicate: MaskitoElementPredicate = async el => (el as HTMLIonInputElement).getInputElement();
  iso: CountryCode = 'KE'
  public maskitoPhoneOptions: MaskitoOptions = maskitoPhoneOptionsGenerator({
    strict: true,
    countryIsoCode: this.iso, 
    metadata,
  });

  constructor(
    public formBuilder: FormBuilder,
    private toastService: ToastService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.payIntegrationForm = this.formBuilder.group({
      phonenumber: ['',
        [
          Validators.required,
        ]
      ],
      amount: [this.transaction.amount],
    })
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm(requestData: any) {
    return this.modalCtrl.dismiss(requestData, 'confirm');
  }

  submitForm() {
    if (!this.payIntegrationForm.valid) {
      return;
    } else {
      let value = this.payIntegrationForm.value.phonenumber;
      const parsedPhoneNumber = parsePhoneNumber(value)
      if(!parsedPhoneNumber?.isValid()) {
        this.toastService.presentToast('Invalid Phone Number', "danger");
        return;
      }

      const requestData = structuredClone(this.payIntegrationForm.value);
      requestData.phonenumber = parsedPhoneNumber?.number;
      requestData.type = 'KE_DTB_MPESA';

      return this.confirm(requestData);
    }
  }

  async payWithStripe() {
    await Stripe.createPaymentSheet({
      merchantDisplayName: 'Kiddy Cash',
      paymentIntentClientSecret: this.stripe_data.paymentIntent,
      customerId: this.stripe_data.customer,
      customerEphemeralKeySecret: this.stripe_data.ephemeralKey,
    });

    Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
      console.log('PaymentSheetEventsEnum.Completed');
    });

    // present PaymentSheet and get result.
    const { paymentResult } = await Stripe.presentPaymentSheet();

    console.log('paymentResult : ', paymentResult);

    this.toastService.presentToast('Payment Processing', 'success');
    return this.confirm(null);
  }
}
