import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ColumnDepositComponent } from './deposit/deposit.component';
import { ColumnSetupComponent } from './setup/setup.component';
import { ColumnWithdrawComponent } from './withdraw/withdraw.component';
import { ColumnPayComponent } from './pay/pay.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskitoDirective, MaskitoPipe } from '@maskito/angular';

@NgModule({
  declarations: [
    ColumnDepositComponent,
    ColumnSetupComponent,
    ColumnWithdrawComponent,
    ColumnPayComponent,
  ],
  exports: [
    ColumnDepositComponent,
    ColumnSetupComponent,
    ColumnWithdrawComponent,
    ColumnPayComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DatePipe, 
    IonicModule, 
    RouterModule,
    MaskitoDirective,
    MaskitoPipe,
  ]
})
export class ColumnModule { }