// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyDXq6FCLEKkTuLmYjSm2VgkQpPnC6wnJLg",
    authDomain: "dev-kiddy-cash.firebaseapp.com",
    databaseURL: "https://dev-kiddy-cash-default-rtdb.firebaseio.com",
    projectId: "dev-kiddy-cash",
    storageBucket: "dev-kiddy-cash.appspot.com",
    messagingSenderId: "558421973028",
    appId: "1:558421973028:web:25ab86525a947dcb7e60b1",
    measurementId: "G-10YG4N37TP",
    vapidKey: "BFyJXkHyWEDjVNZ2NgtB6aXiWVxYHEpkIuAC4pkaTmpjEJSzS3_40qAgL275wezjmJOoKg6XQSG_dBjsIMPGrIc",
  },
  cloudflare: {
    turnstile: {
      sitekey: '0x4AAAAAAA12SNj2lAZKIwUe',
    }
  },
  baseUrl: 'https://dev.kiddy.cash',
  staticBaseUrl: 'https://dev-static.kiddy.cash',
  apiBaseUrl: 'https://dev-api.kiddy.cash/api',
  authBaseUrl: 'https://dev-api.kiddy.cash/auth',
  dataBaseUrl: 'https://dev-api.kiddy.cash/data',
  production: false,
  sentry: {
    environment: 'development',
    dsn: 'https://ac1f565bc269f71c35ea2ecb4a9be34f@o4508669114056704.ingest.us.sentry.io/4508669119496192',
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/dev\.kiddy\.cash\/api/,
      /^https:\/\/dev\.kiddy\.cash\/auth/,
      /^https:\/\/dev\.kiddy\.cash\/data/,
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0, // 0.1 sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  },
  stripe: {
    publishableKey: 'pk_test_51QzbbARQLi1xtjZEh8E8lH4DumylYIr6UyasVOI1qk6QHhuzGexpY1FCbd5jQ3RVDHsP2YpJL6rwQec1J63N0GOL00C39OjQwl'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
