
<ion-row class="ion-align-items-center ion-justify-content-center">
  <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">
    <ion-accordion-group value="stk_push">
      <ion-accordion value="stk_push">
        <ion-item slot="header" color="light">
          <ion-label>Mpesa STK Push</ion-label>
        </ion-item>
        <div class="ion-padding" slot="content">
          <form [formGroup]="mpesaSTKPushForm" (ngSubmit)="submitMpesaSTKPushForm()">
            <ion-item fill="outline" class="transparent-background" lines="none">
              <ion-input
                label="Phone number"
                labelPlacement="floating"
                [clearInput]="true"
                placeholder="Phone number"
                formControlName="phonenumber"
                type="tel"
                [maskito]="maskitoPhoneOptions"
                [maskitoElement]="maskPredicate"            
                inputmode="numeric"
            ></ion-input>
            </ion-item>
  
            <br />
  
            <ion-item fill="outline" class="transparent-background" lines="none">
              <ion-input
                label="Amount"
                labelPlacement="floating"
                type="number"
                placeholder="Amount"
                formControlName="amount"
                inputmode="numeric"
                min="1"
              ></ion-input>
            </ion-item>
  
            <ion-button
              expand="full"
              color="darkpurple"
              type="submit"
              [disabled]="!mpesaSTKPushForm.valid">
              Top-up
            </ion-button>
          </form>
        </div>
      </ion-accordion>

      <ion-accordion value="bank_transfer">
        <ion-item slot="header" color="light">
          <ion-label>Bank Account Details</ion-label>
        </ion-item>
        <div class="ion-padding" slot="content">
          <br />

          <ion-list [inset]="true">
            <ion-item>
              <ion-label>Currency</ion-label>
              <ion-text slot="end">{{ bank_account.currency }}</ion-text>
            </ion-item>

            <ion-item>
              <ion-label>Account Number</ion-label>
              <ion-text slot="end">{{ bank_account.accountNumber }}</ion-text>
            </ion-item>

            <ion-item>
              <ion-label>Account Name</ion-label>
              <ion-text slot="end">{{ bank_account.name }}</ion-text>
            </ion-item>
          </ion-list>

          <br />
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-col>
</ion-row>