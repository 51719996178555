import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient) { }

  putProfile(data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/profile`, data);
  }

  getKyc() {
    return this.http.get<any>(`${environment.apiBaseUrl}/profile/know-your-customer`);
  }

  getDocument(document_path: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/profile/document/${document_path}`);
  }

  getProfileBusinessesFollowing() {
    return this.http.get<any>(`${environment.apiBaseUrl}/profile/business/following`);
  }

  getProfileBusinessFollowing(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/profile/business/${business_id}/following`);
  }

  createProfileBusinessFollowing(business_id: string) {
    return this.http.post<any>(`${environment.apiBaseUrl}/profile/business/${business_id}/following`, undefined);
  }

  deleteProfileBusinessFollowing(business_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/profile/business/${business_id}/following`);
  }

  uploadKyc(data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/profile/know-your-customer`, data);
  }

  getProfileIntegrations() {
    return this.http.get<any>(`${environment.apiBaseUrl}/profile/integration`);
  }

  payForProfileKyc(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/profile/integration`, data);
  }

}
