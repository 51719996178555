import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DtbAstraModule } from './dtb-astra/dtb-astra.module';
import { ColumnModule } from './column/column.module';
import { GriffinModule } from './griffin/griffin.module';

@NgModule({
  declarations: [],
  exports: [
    ColumnModule,
    DtbAstraModule,
    GriffinModule,
  ],
  imports: [
    CommonModule,
    ColumnModule,
    DtbAstraModule,
    GriffinModule,
  ]
})
export class IntegrationModule { }
