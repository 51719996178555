import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfileIntegrationInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'integration-griffin-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
})
export class GriffinSetupComponent  implements OnInit {
  @Input() profile_integration!: ProfileIntegrationInterface;
  staticBaseUrl: string =  environment.staticBaseUrl;

  @Output() createIntegrationSubmitForm = new EventEmitter<any>();

  createIntegrationForm!: FormGroup;

  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createIntegrationForm = this.formBuilder.group({
      profile_integration_id: [this.profile_integration.id],
    })
  }

  submitForm() {
    if (!this.createIntegrationForm.valid) {
      return;
    } else {
      const requestData = structuredClone(this.createIntegrationForm.value);
      this.createIntegrationSubmitForm.emit(requestData);
    }
  }

}
