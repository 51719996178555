import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { IonInput, ModalController } from '@ionic/angular';
import { CountryInterface, ProfileGenderIdentityEnum, UserInterface } from '@kiddy-cash/common';
import { CountryService, ToastService, VerifyService } from 'src/app/services';
import { WhiteSpaceValidator } from 'src/shared/directives/whitespace-validator';
import {maskitoPhoneOptionsGenerator} from '@maskito/phone';
import metadata from 'libphonenumber-js/mobile/metadata';
import { MaskitoOptions, MaskitoElementPredicate } from "@maskito/core";
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-manager-information',
  templateUrl: './manager-information.component.html',
  styleUrls: ['./manager-information.component.scss'],
})
export class ManagerInformationComponent  implements OnInit, OnDestroy {
  @ViewChild('alias', {  static: false })  inputElement!: IonInput;
  @Input() manager: any;
  @Input() canCreate: boolean = true;

  selectedCountry: CountryInterface | undefined;

  name!: string;
  ProfileGenderIdentityEnumValues = Object.values(ProfileGenderIdentityEnum);

  isPhoneAuth: boolean = true;
  isEmailAuth: boolean = false;
  isCodeSent: boolean = false;
  isCodeVerified: boolean = false;
  isProcessing: boolean = false;
  isReadonly: boolean = false;
  errorMessage: string | null = null;

  managerForm: FormGroup = this.formBuilder.group({
    managerId: [null],
    phone: [undefined],
    email: [undefined, Validators.email],
    code: ['', Validators.required],
    alias: ['', [Validators.required, WhiteSpaceValidator.containsOnlySpaces]],
    firstname: [{ value: null, disabled: true }, [Validators.required, WhiteSpaceValidator.containsOnlySpaces]],
    lastname: [{ value: null, disabled: true }, [Validators.required, WhiteSpaceValidator.containsOnlySpaces]],
    genderidentity: [{ value: null, disabled: true }, Validators.required],
    pin: [{ value: null, disabled: true }, [
        Validators.required, 
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(4),
        Validators.maxLength(4),
      ]
    ],
    dob: [{ value: null, disabled: true }, Validators.required],
    valid: [null]
  })

  staticBaseUrl: string = environment.staticBaseUrl

  readonly maskPredicate: MaskitoElementPredicate = async el => (el as HTMLIonInputElement).getInputElement();
  public maskitoPhoneOptions!: MaskitoOptions;

  todayDate: string = new Date().toISOString().split('T')[0];

  resendInSecs: number = 60;
  otpResendInSec: number = this.resendInSecs;
  resendOTP = setInterval(() => {
    this.otpResendInSec = this.otpResendInSec - 1;
  }, 1000);

  constructor(
    private verifyService: VerifyService, 
    private modalCtrl: ModalController, 
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private toastService: ToastService,
    ) {}

  ngOnInit() {
    this.managerForm.patchValue({
      managerId: this.manager.managerId,
      phone: this.manager.phone,
      email: this.manager.email,
      code: this.manager.code,
      alias: this.manager.alias,
      firstname: this.manager.firstname,
      lastname: this.manager.lastname,
      genderidentity: this.manager.genderidentity,
      pin: this.manager.pin,
      dob: this.manager.dob,
      valid: this.manager.valid
    })

    this.countryService._$selectedCountry.subscribe((country: CountryInterface | undefined) => {
      if(!country) return;
      this.selectedCountry = country;

      const iso: CountryCode = country.iso2 as CountryCode
      this.maskitoPhoneOptions = maskitoPhoneOptionsGenerator({
        strict: true,
        countryIsoCode: iso, 
        metadata,
      });
    });
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    const data = this.managerForm.getRawValue();
    data.valid = true
    return this.modalCtrl.dismiss(data, 'confirm');
  }

  countrySelectionChanged(country: CountryInterface) {
    this.countryService._$selectedCountry.next(country)
  }

  toggleAuthType(authType: string){
    if(authType === 'phone') {
      this.isPhoneAuth = true;
      this.isEmailAuth = false;
      this.managerForm.get('email')?.reset(undefined);
    } else {
      this.isPhoneAuth = false;
      this.isEmailAuth = true;
      this.managerForm.get('phone')?.reset(undefined);
    }
  }

  sendCode(){
    if(this.isPhoneAuth) {
      const phone =  this.managerForm.value.phone;
      if(this.managerForm.get('phone')?.valid !== true) return;

      const parsedPhoneNumber = parsePhoneNumber(phone)
      const phoneData = parsedPhoneNumber?.number as string;

      this.isProcessing = true;
      this.errorMessage = null;

      this.verifyService.requestPhoneOTP(phoneData).subscribe((res: any) => {
        this.isProcessing = false;
        this.isCodeSent = true;
        this.isCodeVerified = false;
        this.otpResendInSec = this.resendInSecs;
        if(res.data?.code) this.managerForm.patchValue({code: res.data.code});
      },
      (err: any) => {
        this.isProcessing = false;
        this.errorMessage = err.error.message
      })
    }

    if(this.isEmailAuth) {
      const email =  this.managerForm.value.email;
      if(this.managerForm.get('email')?.valid !== true) return;

      this.isProcessing = true;
      this.errorMessage = null;

      this.verifyService.requestEmailOTP(email).subscribe((res: any) => {
        this.isProcessing = false;
        this.isCodeSent = true;
        this.isCodeVerified = false;  
        this.otpResendInSec = this.resendInSecs;
        if(res.data?.code) this.managerForm.patchValue({code: res.data.code});
      },
      (err: any) => {
        this.isProcessing = false;
        this.errorMessage = err.error.message
      })
    }
  }

  verifyCode(){
    if(this.managerForm.get('code')?.valid !== true) return;

    const code =  this.managerForm.value.code;

    if(this.isPhoneAuth) {
      const phone =  this.managerForm.value.phone;
      if(this.managerForm.get('phone')?.valid !== true) return;

      const parsedPhoneNumber = parsePhoneNumber(phone)
      const phoneData = parsedPhoneNumber?.number as string;

      if(!parsedPhoneNumber?.isValid()) {
        this.toastService.presentToast('Invalid Phone Number', "danger");
        return;
      }

      this.isProcessing = true;
      this.errorMessage = null;

      this.verifyService.verifyPhoneOTP(phoneData, code).subscribe((res: any) => {
        const user: UserInterface = res.data.user;

        this.isProcessing = false;

        if(user){
          this.managerForm.patchValue({
            firstname: user.profile.firstname,
            lastname: user.profile.lastname,
            genderidentity: user.profile.genderidentity,
            dob: user.profile.dob,
            pin: '0000',
          });
          this.isReadonly = true;
        } else {
          if(this.canCreate === true) {
            for (const control in this.managerForm.controls) {
              this.managerForm.controls[control].enable();
            }
            this.isReadonly = false;  
          } else {
            this.errorMessage = 'User Not Found'
            return;
          }
        }

        this.managerForm.get('phone')?.disable();
        this.managerForm.get('code')?.disable();

        this.isCodeSent = true;
        this.isCodeVerified = true;
        setTimeout(() => {
          this.inputElement.setFocus();
        }, 500);
      },
      (err: any) => {
        this.isProcessing = false;
        this.errorMessage = err.error.message
      })
    }

    if(this.isEmailAuth) {
      const email =  this.managerForm.value.email;
      if(this.managerForm.get('email')?.valid !== true) return;

      this.isProcessing = true;
      this.errorMessage = null;

      this.verifyService.verifyEmailOTP(email, code).subscribe((res: any) => {
        const user: UserInterface = res.data.user;

        this.isProcessing = false;

        if(user){
          this.managerForm.patchValue({
            firstname: user.profile.firstname,
            lastname: user.profile.lastname,
            genderidentity: user.profile.genderidentity,
            dob: user.profile.dob,
            pin: '0000',
          });
          this.isReadonly = true;
        } else {
          if(this.canCreate === true) {
            for (const control in this.managerForm.controls) {
              this.managerForm.controls[control].enable();
            }
            this.isReadonly = false;  
          } else {
            this.errorMessage = 'User Not Found'
            return;
          }
        }

        this.managerForm.get('email')?.disable();
        this.managerForm.get('code')?.disable();

        this.isCodeSent = true;
        this.isCodeVerified = true;  
        setTimeout(() => {
          this.inputElement.setFocus();
        }, 500);
      },
      (err: any) => {
        this.isProcessing = false;
        this.errorMessage = err.error.message
      })
    }
  }

  ngOnDestroy() {
    clearInterval(this.resendOTP);
  }

}
