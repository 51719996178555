
<ion-row class="ion-align-items-center ion-justify-content-center">
  <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">
    <ion-accordion-group [multiple]="true" value="bank_transfer">
      <ion-accordion value="bank_transfer">
        <ion-item slot="header" color="light">
          <ion-label>Your Bank Details</ion-label>
        </ion-item>
        <div class="ion-padding" slot="content">
          <br />

          <ion-list [inset]="true">
            <ion-item>
              <ion-label>Swift BIC Code</ion-label>
              <ion-text slot="end">{{ bank_account.bic }}</ion-text>
            </ion-item>

            <ion-item>
              <ion-label>Currency</ion-label>
              <ion-text slot="end">{{ bank_account.currency_code }}</ion-text>
            </ion-item>

            <ion-item>
              <ion-label>Account Number</ion-label>
              <ion-text slot="end">{{ bank_account.default_account_number }}</ion-text>
            </ion-item>

            <ion-item>
              <ion-label>Account Name</ion-label>
              <ion-text slot="end">{{ bank_account.description }}</ion-text>
            </ion-item>  

            <ion-item>
              <ion-label>Routing Number</ion-label>
              <ion-text slot="end">{{ bank_account.routing_number }}</ion-text>
            </ion-item>  
          </ion-list>

          <br />
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-col>
</ion-row>