import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { GriffinDepositComponent } from './deposit/deposit.component';
import { GriffinSetupComponent } from './setup/setup.component';
import { GriffinWithdrawComponent } from './withdraw/withdraw.component';
import { GriffinPayComponent } from './pay/pay.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskitoDirective, MaskitoPipe } from '@maskito/angular';

@NgModule({
  declarations: [
    GriffinDepositComponent,
    GriffinSetupComponent,
    GriffinWithdrawComponent,
    GriffinPayComponent,
  ],
  exports: [
    GriffinDepositComponent,
    GriffinSetupComponent,
    GriffinWithdrawComponent,
    GriffinPayComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DatePipe, 
    IonicModule, 
    RouterModule,
    MaskitoDirective,
    MaskitoPipe,
  ]
})
export class GriffinModule { }