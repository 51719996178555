import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/services';
import { StripePaymentInterface, TransactionInterface } from '@kiddy-cash/common';
import { ModalController } from '@ionic/angular';
import { Stripe, PaymentSheetEventsEnum } from '@capacitor-community/stripe';

@Component({
  standalone: false,
  selector: 'integration-griffin-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
})
export class GriffinPayComponent  implements OnInit {
  @Input() transaction!: TransactionInterface;
  @Input() stripe_data!: StripePaymentInterface;

  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor(
    private toastService: ToastService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm(requestData: any) {
    return this.modalCtrl.dismiss(requestData, 'confirm');
  }

  async payWithStripe() {
    await Stripe.createPaymentSheet({
      merchantDisplayName: 'Kiddy Cash',
      paymentIntentClientSecret: this.stripe_data.paymentIntent,
      customerId: this.stripe_data.customer,
      customerEphemeralKeySecret: this.stripe_data.ephemeralKey,
    });

    Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
      console.log('PaymentSheetEventsEnum.Completed');
    });

    // present PaymentSheet and get result.
    const { paymentResult } = await Stripe.presentPaymentSheet();

    console.log('paymentResult : ', paymentResult);

    this.toastService.presentToast('Payment Processing', 'success');
    return this.confirm(null);
  }
}
