import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/services';
import { AccountIntegrationInterface } from '@kiddy-cash/common';

@Component({
  standalone: false,
  selector: 'integration-column-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss'],
})
export class ColumnWithdrawComponent  implements OnInit {
  @Input() account_integration: AccountIntegrationInterface | undefined;
  @Input() bank_account: any;

  staticBaseUrl: string =  environment.staticBaseUrl;

  @Output() withdrawIntegrationSubmitForm = new EventEmitter<any>();

  constructor(
    private toastService: ToastService,
  ) { }

  ngOnInit() { }

}
